let base22MessageStyle =
  'font-size:21px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:#009B3A;'

console.log('%cMotor de Vendas - SEGASP', base22MessageStyle)

class Config {
  constructor() {
    // this.API_URL = 'https://api.cubix.luby.com.br/segasp/api/api/'
    this.API_URL = 'https://api-cubix.univalores.com.br/segasp/api/api/'
  }
}

export default Config
