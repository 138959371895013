<template>
  <div>
    <h1>Perfil</h1>
    <main class="row">
      <div
        class="col-12 col-lg-4 d-flex justify-content-center align-items-center flex-column"
      >
        <b-avatar
          :src="fileUrl || user.pessoa.funcionario.logo_funcionario_url"
          size="6rem"
          class="mb-3"
          data-test-id="avatar"
        ></b-avatar>
        <div
          v-if="updatingAvatar"
          class="d-flex align-items-center"
          data-test-id="avatar-input-group"
        >
          <b-form-file class="mr-2" v-model="avatarFile" />
          <b-button
            variant="primary"
            v-if="!!avatarFile"
            @click="saveAvatar()"
            class="mr-2"
            :disabled="loading"
            data-test-id="upload-avatar-button"
            >{{ loading ? 'Enviando...' : 'Salvar' }}</b-button
          >
          <b-button
            @click="toggleAvatarInput"
            v-if="!loading"
            data-test-id="cancel-avatar-update-button"
            >Cancelar</b-button
          >
        </div>
        <b-button
          v-else
          @click="toggleAvatarInput"
          data-test-id="toggle-avatar-button"
          variant="primary"
        >
          Alterar Avatar
        </b-button>
      </div>

      <form class="col-12 col-lg-8 row" @submit.prevent="saveHandler">
        <FormGroup label="Nome" labelFor="name-field">
          <b-form-input
            v-model="name"
            placeholder="Nome"
            id="name-field"
            :state="fieldState.name"
          />
          <b-form-invalid-feedback v-if="errors.name">
            <span v-for="error in errors.name" :key="error">{{ error }}</span>
          </b-form-invalid-feedback>
        </FormGroup>
        <FormGroup label="E-mail" labelFor="email-field">
          <b-form-input
            v-model="email"
            placeholder="E-mail"
            type="email"
            id="email-field"
            :state="fieldState.email"
          />
          <b-form-invalid-feedback v-if="errors.email">
            <span v-for="error in errors.email" :key="error">{{ error }}</span>
          </b-form-invalid-feedback>
        </FormGroup>
        <FormGroup label="Login" labelFor="username-field">
          <b-form-input
            v-model="username"
            placeholder="Login"
            id="username-field"
            :state="fieldState.username"
          />
          <b-form-invalid-feedback v-if="errors.username">
            <span v-for="error in errors.username" :key="error">{{
              error
            }}</span>
          </b-form-invalid-feedback>
        </FormGroup>
        <FormGroup label="Telefone" labelFor="phone-field">
          <b-form-input
            v-model="phone"
            placeholder="Telefone"
            id="phone-field"
            v-mask="'(##) #####-####'"
            :state="fieldState.phone"
          />
          <b-form-invalid-feedback v-if="errors.phone">
            <span v-for="error in errors.phone" :key="error">{{ error }}</span>
          </b-form-invalid-feedback>
        </FormGroup>
        <FormGroup label="Senha" labelFor="password-field">
          <b-form-input
            v-model="password"
            placeholder="Trocar de Senha"
            id="password-field"
            :state="fieldState.password"
            type="password"
          />
          <b-form-invalid-feedback v-if="errors.password">
            <span v-for="error in errors.password" :key="error">{{
              error
            }}</span>
          </b-form-invalid-feedback>
        </FormGroup>
        <!-- <FormGroup label="Estado" labelFor="state-field">
          <b-form-select
            v-model="state"
            :options="stateOptions"
            data-test-id="state-field"
            id="state-field"
          />
        </FormGroup>
        <FormGroup label="Cidade" labelFor="city-field">
          <b-form-select
            v-model="city"
            :options="cityOptions"
            data-test-id="city-field"
            id="city-field"
          />
        </FormGroup> -->
        <FormGroup label="Termos de uso" labelFor="use-terms">
          <div class="d-flex align-items-center">
            <template v-if="attachingTerms">
              <b-form-file
                id="use-terms"
                v-model="termsFile"
                :disabled="uploadingTerms"
              />
              <b-button
                variant="primary"
                class="ml-2"
                v-if="termsFile"
                data-test-id="upload-button"
                :disabled="uploadingTerms"
                @click="saveTerms"
              >
                {{ uploadingTerms ? 'Salvando...' : 'Salvar' }}
              </b-button>
              <b-button
                class="ml-2"
                @click="attachingTerms = false"
                data-test-id="cancel-upload-button"
                :disabled="uploadingTerms"
              >
                Cancelar
              </b-button>
            </template>
            <template v-else>
              <span data-test-id="use-terms-upload-status">
                {{ termsFileName || 'Não enviado' }}
              </span>
              <b-button
                variant="primary"
                class="ml-auto"
                @click="attachingTerms = true"
                data-test-id="update-terms-button"
              >
                {{ termsWereUploaded ? 'Alterar' : 'Enviar' }}
              </b-button>
              <b-button
                class="ml-2"
                @click="downloadTerms"
                data-test-id="download-terms-button"
              >
                Baixar modelo
              </b-button>
              <b-button
                class="ml-2"
                @click="downloadFilledTerms"
                data-test-id="download-filled-terms-button"
                v-if="termsFileName"
              >
                Baixar meu envio
              </b-button>
            </template>
          </div>
        </FormGroup>
        <FormGroup class="mt-4">
          <b-button
            class="mr-2"
            variant="primary"
            type="submit"
            :disabled="saving"
            data-test-id="save-button"
            >{{ saving ? 'Salvando...' : 'Salvar' }}</b-button
          >
          <b-button
            @click="restoreHandler"
            type="button"
            :disabled="saving"
            data-test-id="restore-button"
            >Restaurar</b-button
          >
        </FormGroup>
        <b-alert :show="!!errors.custom" class="col-12 mt-2" variant="danger">
          <span v-for="error in errors.custom" :key="error">{{ error }}</span>
        </b-alert>
      </form>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  uploadAvatar,
  updateCurrentUser,
  uploadTerms,
  downloadTerms,
} from '@/services/requests/users'
import FormGroup from '@/components/common/FormGroup.vue'
import { citiesOptions } from '@/constants/options'
import createToastMixin from '@/mixins/create-toast-mixin'
import { downloadFile } from '@/helpers'

export default {
  name: 'UserProfile',
  components: { FormGroup },
  mixins: [createToastMixin],
  data() {
    return {
      updatingAvatar: false,
      avatarFile: null,
      fileUrl: null,
      loading: false,
      name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      termsFileName: '',
      // city: '',
      // state: '',
      saving: false,
      errors: {},
      stateOptions: [
        { text: 'Selecione', value: '' },
        ...Object.keys(citiesOptions).map((state) => ({
          text: state,
          value: state,
        })),
      ],
      termsFile: null,
      attachingTerms: false,
      uploadingTerms: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    cityOptions() {
      let result = [{ text: 'Selecione', value: '' }]

      if (!this.state) {
        return result
      }

      return result.concat(
        citiesOptions[this.state].map((city) => ({
          value: city.value.toLowerCase(),
          text: city.text,
        }))
      )
    },
    fieldState() {
      return {
        name: this.errors.name ? false : null,
        email: this.errors.email ? false : null,
        username: this.errors.username ? false : null,
        phone: this.errors.phone ? false : null,
        password: this.errors.password ? false : null,
      }
    },
    termsWereUploaded() {
      return !!this.termsFileName
    },
    employeeId() {
      return this.user.pessoa.funcionario.funcionario_id
    },
  },
  watch: {
    avatarFile() {
      if (this.avatarFile) {
        this.fileUrl = URL.createObjectURL(this.avatarFile)
      }
    },
  },
  mounted() {
    this.$store.dispatch('auth/loadUser').then(this.setUser)
  },
  methods: {
    toggleAvatarInput() {
      this.updatingAvatar = !this.updatingAvatar

      if (!this.updatingAvatar) {
        this.fileUrl = null
        this.avatarFile = null
      }
    },
    async saveAvatar() {
      this.loading = true

      try {
        const result = await uploadAvatar(this.avatarFile, this.employeeId)

        if (result.status === 200) {
          this.updatingAvatar = false
          this.fileUrl = result.data.result.data.logo_funcionario_url
          this.createToast('Seu avatar foi salvo.', 'Sucesso!', 'success')
        } else {
          throw new Error()
        }
      } catch {
        this.createToast(
          'Não foi possível salvar o seu avatar.',
          'Atenção!',
          'danger'
        )
      } finally {
        this.loading = false
      }
    },
    saveHandler() {
      this.saving = true

      const data = {
        name: this.name,
        email: this.email,
        username: this.username,
        phone: this.phone,
        password: this.password,
        // state: this.state,
        // city: this.city,
      }

      updateCurrentUser(data)
        .then((data) => {
          if (data?.status >= 300) {
            throw new Error(data)
          }

          this.createToast('Seus dados foram salvos.', 'Sucesso!', 'success')

          this.$store.dispatch('auth/loadUser')
        })
        .catch((data) => {
          if (data?.errors) {
            const errorMap = {
              nome: 'name',
              email: 'email',
              senha: 'password',
              telefone: 'phone',
              estado: 'state',
              cidade: 'city',
              usuario_username: 'username',
            }

            const errors = {}

            for (const key in data.errors) {
              const fieldKey = errorMap[key]

              if (fieldKey) {
                errors[fieldKey] = data.errors[key]
              } else {
                errors.custom = (errors.custom || []).concat(data.errors[key])
              }
            }

            this.errors = errors
          }

          this.createToast(
            'Não foi possível salvar os seus dados.',
            'Atenção!',
            'danger'
          )
        })
        .finally(() => {
          this.saving = false
        })
    },
    restoreHandler() {
      this.setUser()
    },
    setUser() {
      this.name = this.user.pessoa.pessoa_nome || ''
      this.email = this.user.pessoa.pessoa_email || ''
      this.username = this.user.usuario_username || ''
      // this.state = this.user.pessoa.endereco?.endereco_estado
      //   ? this.user.pessoa.endereco.endereco_estado
      //   : ''
      // this.city = this.user.pessoa.endereco?.endereco_cidade
      //   ? this.user.pessoa.endereco.endereco_cidade.toLowerCase()
      //   : ''
      this.phone = this.user.pessoa.pessoa_celular
      this.termsFileName = this.user.pessoa.funcionario.termos_uso_software
    },
    saveTerms() {
      this.uploadingTerms = true

      uploadTerms(this.termsFile, this.employeeId)
        .then(() => {
          this.termsFileName = this.termsFile.name
          this.attachingTerms = false

          this.createToast(
            'Os termos de uso foram enviados.',
            'Sucesso!',
            'success'
          )
        })
        .catch(() => {
          this.createToast(
            'Não foi enviar os termos de uso.',
            'Atenção!',
            'danger'
          )
        })
        .finally(() => {
          this.uploadingTerms = false
        })
    },
    downloadTerms() {
      downloadFile(
        'files/_CUBIX_Termos_de_Uso_Software_SEGASP-UNIVALORES_02.05.22.pdf'
      )
    },
    downloadFilledTerms() {
      downloadTerms(this.employeeId).then((result) => {
        if (result.status === 200) {
          const file = result.data
          const fileUrl = URL.createObjectURL(file)

          downloadFile(fileUrl, this.termsFileName)

          setTimeout(() => {
            URL.revokeObjectURL(file)
          }, 1000)
        } else {
          this.createToast(
            'Não foi baixar os termos de uso.',
            'Atenção!',
            'danger'
          )
        }
      })
    },
  },
}
</script>
