import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

import App from './App.vue'
import router from './router'
import store from './store'
import { messages } from './lang/lang'
import Config from './config/config'
import api from '@/utils/api'
import VueMask from 'v-mask'

import '@/filters/index'
import '@/mixins/index'
import '@/design/index.scss'
import { getAuth } from './features/auth/auth.helpers'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(VueMask)

Vue.prototype.CONFIG = new Config()

store.state.currentLanguage = localStorage.currentLanguage
  ? localStorage.currentLanguage
  : 'pt_br'

const i18n = new VueI18n({
  locale: store.state.currentLanguage,
  fallbackLocale: 'pt_br',
  messages,
})
store.state.languages = Object.keys(messages)

new Vue({
  router,
  store,
  i18n,

  mounted() {
    api.interceptors.request.use((config) => {
      const auth = getAuth()

      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    })

    api.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        if (error.response?.status === 401) {
          var count = 5

          let countdown = setInterval(() => {
            count -= 1
            document.querySelector(
              '#container-fluid'
            ).innerHTML = `<h5>Sessão expirada! redirecionando em ${count} segundos...</h5>`

            if (count <= 0) {
              clearInterval(countdown)
              store.dispatch('auth/logOut')
              router.push({ path: '/auth/logout' })

              setTimeout(() => {
                document.location.reload(true)
              }, 1000)
            }
          }, 1000)
        }

        return Promise.reject(error)
      }
    )
  },

  render: (h) => h(App),
}).$mount('#app')
