import { getInsuranceCompany } from '@/services/requests/company'

const state = {
  insurers: [],
}

const getters = {
  insurersOptions: (state) => {
    return [
      { value: '', text: 'Selecione' },
      ...state.insurers.map((insurer) => ({ value: insurer, text: insurer })),
      { value: 'Outra', text: 'Outra' },
    ]
  },
}

export const actions = {
  loadInsurers({ state }) {
    getInsuranceCompany()
      .then((res) => {
        state.insurers = res.data.result.data
      })
      .catch(() => {
        this.$store.state.modal.isOpenModalErrorRequest = true
      })
  },
}

export default {
  name: 'diagnosis',
  namespaced: true,
  state,
  getters,
  actions,
}
